<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
    </div>
    <div class="macth_location_tab cr-global-table" v-else>
        <table>
            <tbody>
            <tr>
                <td><b> {{ $t("cric.Local Start Time Venue") }} </b></td>
                <td><p> {{ matchInfo.local_datetime | formatDate }} </p></td>
            </tr>
            <tr>
                <td><b> {{ $t("cric.Local Time") }} </b></td>
                <!-- <td><p> {{ matchInfo.gmt_datetime | formatDate }} </p></td> -->
                <td><p> {{ matchInfo.gmt_datetime | cricDateTimeTz('DD MMM [at] hh:mm A') }} </p></td>
            </tr>
            <tr v-if="matchInfo.toss_win">
                <td><b> Toss </b></td>
                <td><p style="display:inline"> {{matchInfo.toss_win}} won the toss and chose to </p><p
                        style="display:inline">{{matchInfo.elected}}</p></td>
            </tr>
            <tr>
                <td><b> {{ $t("Country") }} </b></td>
                <td><p> {{matchInfo.cricStadium.country}} </p></td>
            </tr>
            <tr>
                <td><b> {{ $t("cric.City") }} </b></td>
                <td><p> {{matchInfo.cricStadium.city}} </p></td>
            </tr>
            <tr>
                <td><b> {{ $t("cric.Venue") }} </b></td>
                <td><p> {{matchInfo.cricStadium.name}} </p></td>
            </tr>
            <tr >
                <td><b> {{ $t("cric.Established") }} </b></td>
                <td><p> {{matchInfo.cricStadium.established}} </p></td>
            </tr>
            <tr v-if="matchInfo.cricMatchStars.length > 0">
                <td><b> {{ $t("cric.Man of the Match") }} </b></td>
                <td><p> {{matchInfo.cricMatchStars[0].player_text}}</p></td>
            </tr>
            <tr v-if="isScheduled()">
                <td><b> {{ $t("Tournament") }}</b></td>
                <td><p> {{matchInfo.tournament.name}} </p></td>
            </tr>
            <tr v-if="isScheduled()">
                <td><b> {{ $t("cric.Match Number") }}</b></td>
                <td><p> {{matchInfo.matchno}} </p></td>
            </tr>
            <tr v-if="isFinished()">
                <td><b> {{ $t("cric.Result") }}</b></td>
                <td><p> {{matchInfo.comment}} </p></td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: "info",

        data() {
            return {
                matchInfo: null,
            }
        },
        mounted() {
            return this.getInfo()
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%HOME_TEAM%': this.$store.getters.getMatchDetailResponse.team1.name,
                '%AWAY_TEAM%': this.$store.getters.getMatchDetailResponse.team2.name,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_info_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_info_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.cricket_match_info_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        serverPrefetch() {
            return this.getInfo();
        },
        methods: {
            getInfo() {
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$MATCH_INFO_API.replace(':match', this.$route.params.slug))
                    .then(response => {
                        if(response.data.result) {
                            this.matchInfo = response.data.result.match;
                            this.is_data_ready = true;
                        }
                    })
            },
            isLive() {
                return this.matchInfo.badgeStatus == 'Live'
            },

            isFinished() {
                return this.matchInfo.badgeStatus == 'Finished'
            },

            isStumps() {
                return this.matchInfo.badgeStatus == 'Stumps'
            },

            isCancelled() {
                return this.matchInfo.badgeStatus == 'Cancelled'
            },

            isScheduled() {
                return this.matchInfo.badgeStatus == 'Scheduled'
            },
        },

    }
</script>
